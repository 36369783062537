import { graphql } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';
import Address from '../components/Address';
import GoogleMap from '../components/GoogleMap';
import { Main } from '../components/Main';
import Text from '../components/typography/Text';
import Title from '../components/typography/Title';
import { RemarkNode } from '../models/RemarkNode';
import { breakpoints } from '../styles/config';

const Container = styled(Main)<{ fixedHeight?: boolean; center?: boolean }>`
    @media (min-width: ${breakpoints.tablet}px) {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: ${p => (p.center ? 'center' : 'space-between')};
        ${p =>
            p.fixedHeight &&
            css`
                height: calc(100vh - 60px);
            `};
    }
    @media (min-width: ${breakpoints.desktop}px) {
        ${p =>
            p.fixedHeight &&
            css`
                height: calc(100vh - 80px);
            `};
    }
`;

const GoogleMapContainer = styled.div`
    width: 100%;
    padding: 0 1rem 3rem;
    flex: none;

    @media (min-width: ${breakpoints.tablet}px) {
        padding: 3rem 3rem 3rem 1.5rem;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
`;

const ContentContainer = styled.div`
    display: block;
    text-align: left;
    padding: 3rem 1rem 1rem;

    @media (min-width: ${breakpoints.tablet}px) {
        padding: 3rem 1.5rem 3rem 3rem;
    }
`;

const AdditionalNote = styled(Text)`
    font-size: 14px;
    line-height: 1.4em;
`;

export interface DirectionsPageProps {
    data: {
        datoCmsDirectionsPage: {
            title: string;
            street: string;
            code: string;
            town: string;
            country: string;
            additionalNoteNode: RemarkNode;
        };
    };
}

export default function DirectionsPage({ data }: DirectionsPageProps): JSX.Element {
    const { title, street, code, town, country, additionalNoteNode } = data.datoCmsDirectionsPage;
    return (
        <Container fixedHeight>
            <ContentContainer>
                <Title>{title}</Title>
                <Address address={{ street, code, town, country }} />
                <AdditionalNote dangerouslySetSanitizedHTML={additionalNoteNode.childMarkdownRemark.html} />
            </ContentContainer>
            <GoogleMapContainer>
                <GoogleMap />
            </GoogleMapContainer>
        </Container>
    );
}

export const query = graphql`
    query DirectionsPage($language: String!) {
        datoCmsDirectionsPage(locale: { eq: $language }) {
            title
            street
            town
            code
            country
            additionalNoteNode {
                ...RemarkNode
            }
        }
    }
`;
