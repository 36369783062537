import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin-bottom: 1.5rem;
`;

const AddressLine = styled.div`
    line-height: 1.5em;
`;

export interface AddressProps {
    address: {
        street: string;
        code: string;
        town: string;
        country: string;
    };
}

export default function Address({ address }: AddressProps): JSX.Element {
    const { street, town, code, country } = address;
    return (
        <Container>
            <AddressLine>{street}</AddressLine>
            <AddressLine>
                {code}, {town}
            </AddressLine>
            <AddressLine>{country}</AddressLine>
        </Container>
    );
}
