import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../styles/config';

export interface GoogleMapProps {}

const Container = styled.div`
    width: 100%;
    height: 100vw;
    @media (min-width: ${breakpoints.tablet}px) {
        height: 100%;
    }
`;

const IFrame = styled.iframe`
    width: 100%;
    height: 100%;
`;

export const GoogleMap: React.FC<GoogleMapProps> = ({}) => {
    return (
        <Container>
            <IFrame
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2501.453290275599!2d16.878477851044146!3d51.17386777948156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470f9521a4866fb7%3A0xb444b5e511901b18!2sMalownicza%205%2C%2054-081%20Wroc%C5%82aw!5e0!3m2!1sen!2spl!4v1610799292519!5m2!1sen!2spl"
                frameBorder="0"
                style={{ border: '0' }}
                aria-hidden="false"
                tabIndex={0}
            />
        </Container>
    );
};

export default GoogleMap;
